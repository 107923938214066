<template>
  <div class="page">
    <div class="container block">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首頁</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/member' }">會員專區</el-breadcrumb-item>
            <el-breadcrumb-item>重置密碼</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page_title">
            <div class="title">重置密碼</div>
        </div>
        <div class="input_block">
          <div class="border">
            <img class="top_left" src="@/assets/images/pop-bg.png">
            <img class="top_right" src="@/assets/images/pop-bg.png">
            <img class="bot_left" src="@/assets/images/pop-bg.png">
            <img class="bot_right" src="@/assets/images/pop-bg.png">
            <el-form ref="form" :model="form">
              <el-form-item label="請輸入新密碼">
                <el-input v-model="form.password"></el-input>
              </el-form-item>
              <el-form-item label="請再次輸入新密碼">
                <el-input v-model="form.passwordConfirm" show-password></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">確認</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
    </div>
    <div class="footer_area">
      <div class="container">
        <p>© 2023 金老師命理世界權威, All Rights Reserved. <br>aurumteacher@gmail.com | 94088632 打遍天下有限公司</p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        form: {
          password: '',
          passwordConfirm: '',
        }
      }
    },
    methods: {
      onSubmit() {
        console.log('submit!');
        this.$router.push({
          name: 'login'
        })
      }
    }
  }
</script>