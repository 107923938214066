<template>
  <div class="home">
    <div class="container">
      <div class="slider">
        <el-carousel height="230px">
          <el-carousel-item>
            1
          </el-carousel-item>
          <el-carousel-item>
            2
          </el-carousel-item>
          <el-carousel-item>
            3
          </el-carousel-item>
        </el-carousel>
      </div>
      <p class="title_area"><img src="@/assets/images/idx-title.png"></p>
      <div class="button_list">
        <ul class="buttons">
          <li v-on:click="dialogReserve = true"><img src="@/assets/images/idx-icon-1.png"> 占卜</li>
          <li v-on:click="dialogReserve = true"><img src="@/assets/images/idx-icon-2.png"> 風水</li>
          <li v-on:click="dialogReserve = true"><img src="@/assets/images/idx-icon-3.png"> 姓名</li>
          <li v-on:click="dialogReserve = true"><img src="@/assets/images/idx-icon-1.png">塔羅</li>
          <li v-on:click="dialogReserve = true"><img src="@/assets/images/idx-icon-4.png"> 生命靈數</li>
          <li v-on:click="dialogReserve = true"><img src="@/assets/images/idx-icon-4.png">各式號碼吉凶</li>
          <li class="last">開運補財商品區</li>
        </ul>
        <div class="tablet_img"><img src="@/assets/images/idx-mobile-img-m.png"></div>
        <div class="mobile_img"><img src="@/assets/images/idx-mobile-img.png"></div>
      </div>
    </div>
    <div class="footer_area">
      <div class="container">
        <div class="left_img">1<img src="@/assets/images/idx-left-img.png"></div>
        <div class="right_img">2<img src="@/assets/images/idx-right-img.png"></div>
        <p>© 2023 金老師命理世界權威, All Rights Reserved. <br>aurumteacher@gmail.com | 94088632 打遍天下有限公司</p>
      </div>
    </div>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!--注意事項-->
    <el-dialog :visible.sync="dialogReserve"
        width="400px"
        :show-close = false
        custom-class="pop_window">
        <template>
            <div class="border">
                <img class="top_left" src="@/assets/images/pop-bg.png">
                <img class="top_right" src="@/assets/images/pop-bg.png">
                <img class="bot_left" src="@/assets/images/pop-bg.png">
                <img class="bot_right" src="@/assets/images/pop-bg.png">
                <p class="title">預約占卜</p>
                <div class="pop_content">
                    <img style="width: 100%; height: 130px; background: #ddd">
                    <p align="left">簡介文字簡介文字簡介文字簡介文字簡介文字簡介文字簡介文字簡介文字簡介文字簡介文字簡介文字簡介文字簡介文字</p>
                </div>
                <!-- <div class="pop_content">
                    <div align="left"><b>預約時間</b></div>
                    <el-date-picker
                      v-model="value_date"
                      align="right"
                      type="date"
                      placeholder="選擇日期"
                      :picker-options="pickerOptions">
                    </el-date-picker>
                    <el-time-select
                      v-model="value_time"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30'
                      }"
                      placeholder="選擇時間">
                    </el-time-select>
                </div> -->
                <div class="pop_content">
                    <el-button type="primary" @click="dialogReserve = false">確認預約</el-button>
                    <el-button class="close" type="primary" @click="dialogReserve = false">取消</el-button>
                </div>
            </div>
        </template>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  data() {
      return {
        dialogReserve: false,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
        value_date: '',
        value_time: ''
      };
    }
}
</script>
