<template>
  <div class="page">
    <div class="container block">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首頁</el-breadcrumb-item>
            <el-breadcrumb-item><a href="/">會員專區</a></el-breadcrumb-item>
            <el-breadcrumb-item><a href="/">註冊</a></el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page_title">
            <div class="title">註冊會員</div>
        </div>
        <div class="input_block">
          <div class="border">
            <img class="top_left" src="@/assets/images/pop-bg.png">
            <img class="top_right" src="@/assets/images/pop-bg.png">
            <img class="bot_left" src="@/assets/images/pop-bg.png">
            <img class="bot_right" src="@/assets/images/pop-bg.png">
            <el-form ref="form" :model="form">
              <el-form-item label="請輸入帳號">
                <el-input v-model="form.account"></el-input>
              </el-form-item>
              <el-form-item label="請輸入密碼">
                <el-input v-model="form.password" show-password></el-input>
              </el-form-item>
              <el-form-item label="請再次輸入密碼">
                <el-input v-model="form.password2" show-password></el-input>
              </el-form-item>
              <el-form-item label="請輸入生日">
                <el-input v-model="form.birthday" show-password></el-input>
              </el-form-item>
              <el-form-item label="請輸入手機">
                <el-input v-model="form.phone" show-password></el-input>
              </el-form-item>
              <el-form-item label="請輸入E-mail">
                <el-input v-model="form.email" show-password></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary">註冊會員</el-button>
                <router-link :to="{ name: 'login'}"><el-button>登入會員</el-button></router-link>
              </el-form-item>
            </el-form>
          </div>
        </div>
    </div>
    <div class="footer_area">
      <div class="container">
        <p>© 2023 金老師命理世界權威, All Rights Reserved.  <br>aurumteacher@gmail.com | 94088632 打遍天下有限公司</p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        form: {
          account: '',
          password: '',
          password2: '',
          birthday: '',
          phone: '',
          email: '',
        }
      }
    },
    methods: {
      onSubmit() {
        console.log('submit!');
      }
    }
  }
</script>