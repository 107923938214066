<template>
  <div class="page">
    <div class="container block">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首頁</el-breadcrumb-item>
            <el-breadcrumb-item><a href="/">生活小軍師</a></el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page_title">
            <div class="title">請選擇您要問的事情</div>
            <span>可占卜次數: 0</span><button @click="dialogVisible = true">購買</button>
        </div>
        <div class="list_area">
            <ul>
                <router-link :to="{ name: 'serviceItem'}"><li>運勢</li></router-link>
                <li>婚姻</li>
                <li>愛情</li>
                <li>考試</li>
                <li>經商</li>
                <li>疾病</li>
                <li>求職</li>
                <li>事業</li>
                <li>決策</li>
                <li>胎孕</li>
                <li>家運</li>
                <li>訴訟</li>
                <li>開業</li>
                <li>尋人</li>
                <li>失物</li>
                <li>改行</li>
                <li>旅行</li>
                <li>週轉</li>
                <li>買賣</li>
                <li>子女</li>
            </ul>
        </div>
    </div>
    <div class="footer_area">
      <div class="container">
        <p>© 2023 金老師命理世界權威, All Rights Reserved. <br>aurumteacher@gmail.com | 94088632 打遍天下有限公司</p>
      </div>
    </div>
    <el-dialog
        :visible.sync="dialogVisible"
        width="450px"
        :show-close = false
        custom-class="pop_window"
        >
        <template>
            <div class="border">
                <img class="top_left" src="@/assets/images/pop-bg.png">
                <img class="top_right" src="@/assets/images/pop-bg.png">
                <img class="bot_left" src="@/assets/images/pop-bg.png">
                <img class="bot_right" src="@/assets/images/pop-bg.png">
                <p class="title">購買占卜次數</p>
                <div class="pop_content">
                    <el-input-number v-model="num" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number>
                </div>
                <div class="pop_content">
                    <span class="total">此次購買次數: {{num}} 次</span>
                    <span class="total">此次購買金額: {{cost}} 次</span>
                </div>
                <div class="pop_content">
                    <el-button type="primary" @click="dialogVisible = false">確認購買</el-button>
                    <el-button class="close" type="primary" @click="dialogVisible = false">取消</el-button>
                </div>
            </div>
        </template>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'ServiceList',
  components: {
    // HelloWorld
  },
  data() {
    return {
        dialogVisible: false,
        num: 1,
        cost: 0,
    };
  },
  methods: {
        handleChange(value) {
        console.log(value);
        }
    }
}
</script>
