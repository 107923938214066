import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import ForgotPwd from '../views/ForgotPwd.vue'
import ResetPwd from '../views/ResetPwd.vue'
import Member from '../views/Member.vue'
import ServiceList from '../views/ServiceList.vue'
import ServiceItem from '../views/ServiceItem.vue'
import ServiceResult from '../views/ServiceResult.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/sign-up',
    name: 'signup',
    component: Signup
  },
  {
    path: '/forgot-pwd',
    name: 'forgotPwd',
    component: ForgotPwd
  },
  {
    path: '/reset-pwd',
    name: 'resetPwd',
    component: ResetPwd
  },
  {
    path: '/member',
    name: 'member',
    component: Member
  },
  {
    path: '/service-list',
    name: 'serviceList',
    component: ServiceList
  },
  {
    path: '/service-item',
    name: 'serviceItem',
    component: ServiceItem
  },
  {
    path: '/service-result',
    name: 'serviceResult',
    component: ServiceResult
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
