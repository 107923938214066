<template>
  <div class="page">
    <div class="container block">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首頁</el-breadcrumb-item>
            <el-breadcrumb-item>會員專區</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page_title">
            <div class="title">會員專區</div>
            <span>可占卜次數: 0</span><button @click="dialogVisible = true">購買</button>
        </div>
        <div class="page_table">
            <h3>購買紀錄</h3>
            <table cellpadding="0" cellspacing="0">
                <thead>
                    <tr>
                        <td>購買日期</td>
                        <td>購買次數</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>2023.01.01</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>2023.01.01</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>2023.01.01</td>
                        <td>5</td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        

    </div>
    <div class="footer_area">
      <div class="container">
        <p>© 2023 金老師命理世界權威, All Rights Reserved. <br>aurumteacher@gmail.com | 94088632 打遍天下有限公司</p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {

      }
    },
    methods: {
      
    }
  }
</script>