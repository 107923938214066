<template>
  <div class="page">
    <div class="container block">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首頁</el-breadcrumb-item>
            <el-breadcrumb-item><a href="/">生活小軍師</a></el-breadcrumb-item>
            <el-breadcrumb-item><a href="/">運勢</a></el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page_title">
            <div class="title">請選擇您要問的運勢問題</div>
            <span>可占卜次數: 0</span><button @click="dialogVisible = true">購買</button>
        </div>
        <div class="item_area">
            <ul>
                <button @click="dialogNotice = true"><li>問題1</li></button>
                <button @click="dialogNotice = true"><li>問題2</li></button>
                <button @click="dialogNotice = true"><li>問題3</li></button>
                <button @click="dialogNotice = true"><li>問題4</li></button>
                <button @click="dialogNotice = true"><li>問題5</li></button>
            </ul>
        </div>
        <router-link :to="{ name: 'serviceList'}"><el-button type="primary" class="btn_back">回上頁</el-button></router-link>
    </div>
    <div class="footer_area">
      <div class="container">
        <p>© 2023 金老師命理世界權威, All Rights Reserved. <br>aurumteacher@gmail.com | 94088632 打遍天下有限公司</p>
      </div>
    </div>
    <!--購買次數-->
    <el-dialog
        :visible.sync="dialogVisible"
        width="400px"
        :show-close = false
        custom-class="pop_window"
        >
        <template>
            <div class="border">
                <img class="top_left" src="@/assets/images/pop-bg.png">
                <img class="top_right" src="@/assets/images/pop-bg.png">
                <img class="bot_left" src="@/assets/images/pop-bg.png">
                <img class="bot_right" src="@/assets/images/pop-bg.png">
                <p class="title">購買占卜次數</p>
                <div class="pop_content">
                    <el-input-number v-model="num" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number>
                </div>
                <div class="pop_content">
                    <span class="total">此次購買次數: {{num}} 次</span>
                    <span class="total">此次購買金額: {{cost}} 次</span>
                </div>
                <div class="pop_content">
                    <el-button type="primary" @click="dialogVisible = false">確認購買</el-button>
                    <el-button class="close" type="primary" @click="dialogVisible = false">取消</el-button>
                </div>
            </div>
        </template>
    </el-dialog>
    <!--注意事項-->
    <el-dialog :visible.sync="dialogNotice"
        width="400px"
        :show-close = false
        custom-class="pop_window">
        <template>
            <div class="border">
                <img class="top_left" src="@/assets/images/pop-bg.png">
                <img class="top_right" src="@/assets/images/pop-bg.png">
                <img class="bot_left" src="@/assets/images/pop-bg.png">
                <img class="bot_right" src="@/assets/images/pop-bg.png">
                <p class="title">注意事項</p>
                <div class="pop_content">
                    <p><span class="number">1</span>需一個安靜地方</p>
                    <p><span class="number">2</span>專心思考默念問題</p>
                    <p><span class="number">3</span>誠心的心態</p>
                </div>
                <div class="pop_content">
                    <el-button type="primary" @click="openInnerVisible">準備好了</el-button>
                    <el-button class="close" type="primary" @click="dialogNotice = false">再等等</el-button>
                </div>
            </div>
        </template>
    </el-dialog>
    <el-dialog
        width="400px"
        :show-close = false
        :visible.sync="innerVisible"
        custom-class="pop_window"
        >
        <template>
            <div class="border">
                <img class="top_left" src="@/assets/images/pop-bg.png">
                <img class="top_right" src="@/assets/images/pop-bg.png">
                <img class="bot_left" src="@/assets/images/pop-bg.png">
                <img class="bot_right" src="@/assets/images/pop-bg.png">
                <p class="title">占卜中</p>
                <p class="pop_img"><img src="@/assets/images/card.gif"></p>
                <div class="pop_content">
                    <router-link :to="{ name: 'serviceResult'}"><el-button type="primary" @click="innerVisible = false">查看結果</el-button></router-link>
                </div>
            </div>
        </template>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'ServiceList',
  components: {
    // HelloWorld
  },
  data() {
    return {
        dialogVisible: false,
        dialogNotice: false,
        innerVisible: false,
        num: 1,
        cost: 0,
    };
  },
  methods: {
        handleChange(value) {
            console.log(value);
        },
        openInnerVisible() {
            this.dialogNotice = false,
            this.innerVisible = true,
            console.log(this.innerVisible)
        }
    }
}
</script>
